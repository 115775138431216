import { Bar } from 'react-chartjs-2';
import {Chart, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js'
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);
Chart.register(Tooltip);

const GraphComponent = (props) => {

  const numberFormatter = (value) => {
    if (value >= 1e6) {
      // Format as millions
      return (value / 1e6).toFixed(2) + 'M';
    } else if (value >= 1e3) {
      // Format as thousands
      return (value / 1e3).toFixed(2) + 'k';
    } else {
      // Return the value as it is if it's less than 1000
      return value.toString();
    }
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            return `Data for ${tooltipItems[0].label}`;
          },
          label: function (tooltipItem) {
            // console.log(`Value: ${tooltipItem.raw}`);
            return `Value: ${tooltipItem.raw}`;
          },
          footer: function (tooltipItems) {
            let sum = 0;
            tooltipItems.forEach(function (tooltipItem) {
              sum += tooltipItem.raw;
            });
            return `Sum: ${sum}`;
          }
        }
      },
      datalabels: {
        display: true,
        align: 'top',  // or 'center', 'bottom', etc. based on your preference
        color: '#777', 
        formatter: (value) => numberFormatter(value), 
        font: {
          weight: 'bold',
          size: 9,
        },
        rotation: (props.name === 'Top 10 Models using ADA' ? -40 : 0)
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value, index, values) {
            if (props.data.labels[index]?.length > 5) {
              return `${props.data.labels[index].substring(0, 10)}...`;
              // return `${props.data.labels[index]}`;
            } else {
              return props.data.labels[index]?.split('').join('\n'); // split label characters into vertical format
            }
          }
        },
        grid: {
          display: false, // Removes vertical lines
        },
      },
      y: {
        grid: {
          display: false, // Removes vertical lines
        },
      }
    }
  };

    return (
        <Bar data={props.data} options={options} />
    )
}

export default GraphComponent