import React from 'react'
import './Home.css';
// import { useNavigate } from 'react-router-dom';
import GraphLayout from './GraphLayout/GraphLayout';

export default function Home() {
  // const navigate = useNavigate();
  return (
    <div className="home-container">
      <div id='cards-container'>
        {/* <div className='nav-card' onClick={() => navigate('/new-update')}>
          <div style={{position:'relative'}}>
            <div className='nav-card-title'>New Batch Update</div>
            <div className='nav-card-description'>Start a new batch update by entering input parameters and uploading a file.</div>
          </div>
        </div>
        <div className='nav-card' onClick={() => navigate('/update-history')}>
          <div style={{position:'relative'}}>
            <div className='nav-card-title'>Batch Update History</div>
            <div className='nav-card-description'>View past batch updates, find details and download detailed reports.</div>
          </div>
        </div>
        <div className='nav-card' onClick={() => navigate('/batch-analysis')}>
          <div style={{position:'relative'}}>
            <div className='nav-card-title'>Batch Analysis</div>
            <div className='nav-card-description'>Get VIN profiles of upto 100 VINs at once, export as table.</div>
          </div>
        </div> */}
      </div>
      <div>
        <div>
          <GraphLayout />
        </div>
      </div>
    </div>
  )
}