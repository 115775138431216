import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { EnterOutlined } from "@ant-design/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Diagnostic.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AdjustIcon from "@mui/icons-material/Adjust";
import EastIcon from "@mui/icons-material/East";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { floatButtonPrefixCls } from "antd/es/float-button/FloatButton";
import { Spin } from "antd";
import { env } from "../../config";

export default function Diagnostic() {
  const [vin, setVin] = useState("");
  const [vinError, setVinError] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [rows, setRows] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [vins, setVins] = useState([]);
  const [region, setRegion] = useState("na");
  const [showLoader, setLoader] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  let deviceData;
  const handleSearch = () => {
    showTable = true;
  };
  const handleBackButton = () => {
    setShowTable(false);
    setLoader(false);
  };
  const handleRadioChange = async (event) => {
    setRegion(event.target.value);
    // setDevices(event.target.value);
    // await setSelectedRegionOption(event.target.value);
  };
  const handleDeviceChange = async (event) => {
    setSpinLoader(true);
    console.log('vin')
    const selected = event.target.value;
    if(selected == 'vin'){
      getDiagnose();
     
    }
    else{
      setSelectedDevice(selected);
      getOnchangeDevice(selected);
    }
    
    // getDevice()
  };
  const handleClose1 = () => setAlertShow(false);
  const handleShow1 = () => setAlertShow(true);
  const [alertShow, setAlertShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const handleSubmit = () => {
    let data = {
      vin: vin,
      region: region,
    };
  };
  const handleRowClick = (item) => {
    setSelectedRow(item.details);
    // console.log(item.details);
    handleShow1(true);
    // console.log(iden)
    // const modalDataObj = {
    //   ...reqRow
    // };
    // setModalData(modalDataObj);
    // setOpenModal(true);
  };
  // const handleKeyPress = (event) => {
  //   console.log(event)
  //   // if(event.key === 'Enter'){
  //   //   console.log('enter press here! ')
  //   // }
  // }
  const getDiagnose = async () => {
    let response;
    try {
      setLoader(true);
      response = await axios.post(
        "https://ziedjkz9lj.execute-api.us-west-1.amazonaws.com/vin-diagnose",
        {
          vin: vin,
          region: region,
        }
      );
      setRows(response.data);
      setDefaultData(response.data);
      setLoader(false);
      setSpinLoader(false);
      setShowTable(true);
    } catch (error) {
      setLoader(false);
      toast.error(error.response.data.msg);
      setShowTable(false);
    }
  };
  const getDevice = async () => {
    let response;
    try {
      setLoader(true);
      response = await axios.post(
        "https://ziedjkz9lj.execute-api.us-west-1.amazonaws.com/devices",
        {
          vin: vin,
          region: region,
        }
      );
      // console.log(response.data,'---->>>>>>>>>------');
      setDevices(response.data);
      // setSelectedDevice(response.data[0])
      setLoader(false);
      // setShowTable(true);
    } catch (error) {
      setLoader(false);
      // toast.error(error.response.data.msg);
      // setShowTable(false);
    }
  };
  const getOnchangeDevice = async (device) => {
    let response;
    try {
      setLoader(true);
      response = await axios.post(
        "https://ziedjkz9lj.execute-api.us-west-1.amazonaws.com/device-checks",
        {
          vin: vin,
          region: region,
        },
        {
          params: {
            device: device,
          },
        }
      );
      // deviceData = defaultData.concat(response.data)
      deviceData = response.data;
      console.log(deviceData);
      setRows(deviceData);
      setSpinLoader(false);
      setShowTable(true);
    } catch (error) {
      setLoader(false);
      toast.error(error.response.data.msg);
      setShowTable(false);
    }
  };

  return showTable ? (
    
    <div >
       <div className="headercomp">Diagnostic / {vin}</div>
      {spinLoader && <Spin size="large" tip="Loading..." fullscreen={true} />}
     
        <div style={{display:"flex",marginTop:"40px"}}> 
          <button
            className="btn backbtn"
            style={{ height: "35px", display: "flex", width: "89px",padding:"5px" ,marginLeft:"53px"}}
            onClick={handleBackButton}
          >
            <KeyboardBackspaceIcon
              style={{ color: "black", paddingRight: "7px" }}
            />
            <span style={{ paddingTop: "4px",paddingLeft:"4px" }}>Back</span>
          </button>
          <select
            className="selectbox"
            defaultValue=""
            onChange={handleDeviceChange}
            style={{width:"9%",height:"35px",marginLeft:"16px"}}
          >
            <option value="vin" >Vin Level</option>
            {devices.map((device) => (
              <option key={device.id} value={device.id}>
                {device}
              </option>
            ))}
          </select>
        </div>

      {/* <div className="vin">{vin}</div> */}
      <div className="table-layout"> 
        <TableContainer component={Paper}>
          <Table sx={{}} aria-label="simple table">
            <TableHead className="table-header">
              <TableRow>
                <TableCell>Process</TableCell>
                {/* <TableCell>Progress</TableCell> */}
                <TableCell>Status</TableCell>
                {/* <TableCell>Device</TableCell> */}
                <TableCell>Reason</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.timestamp}
                  // sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                >
                  <TableCell>
                    <span>{row.label}</span>
                    <span>
                      <Tooltip title={row.description} placement="top">
                        <InfoOutlinedIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "18px",
                            color: "#7d7d7d",
                            paddingLeft: "20px",
                            marginBottom: "-3px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </TableCell>
                  {/* <TableCell>Done</TableCell> */}
                  <TableCell>
                    {" "}
                    {(row.result == 1 ? (
                      <CheckCircleIcon
                        style={{ color: "green", fontSize: "1.7rem" }}
                      />
                    ) : (
                      ""
                    )) ||
                      (row.result == 0 ? (
                        <CancelRoundedIcon
                          style={{ color: "red", fontSize: "1.7rem" }}
                        />
                      ) : (
                        ""
                      )) ||
                      (row.result = 0.1 ? (
                        <AdjustIcon
                          style={{ color: "#FFBF00", fontSize: "1.7rem" }}
                        />
                      ) : (
                        ""
                      ))}
                  </TableCell>
                  {/* <TableCell>
                    {row.devices.map((ele) => {
                      return <li>{ele}</li>;
                    })}
                  </TableCell> */}
                  {/* <TableCell>{ {{row.overall_result == 0} ? <CheckCircleIcon style={{ color: "green", "fontSize": "1.7rem" }} :''} || {row.overall_result == 1 ? <CancelRoundedIcon style={{ color: "red", "fontSize": "1.7rem" } }</TableCell> */}
                  <TableCell>
                    {/* {row.reason?.map((ele) => {
                      return <li>{ele}</li>;
                    })} */}

                    {row.reason ? (
                      <div>
                        {row.reason.map((ele) => {
                          return <li>{ele}</li>;
                        })}
                      </div>
                    ) : (
                      " - "
                    )}
                  </TableCell>
                  <TableCell onClick={() => handleRowClick(row)}>
                    {" "}
                    <EastIcon
                      style={{
                        color: "#7e7777",
                        fontSize: "1.7rem",
                        cursor: "pointer",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          show={alertShow}
          onHide={handleClose1}
          size="lg"
          scrollable={"true"}
          backdrop="static"
          animation={"false"}
          aria-labelledby="title-terms"
        >
          <Modal.Header className="alert-warning py-3">
            <Modal.Title className="h6">Findings</Modal.Title>
            <span className="close" onClick={handleClose1}>
              &times;
            </span>
          </Modal.Header>
          <Modal.Body>
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="simple table">
                <TableHead className="table-header">
                  <TableRow>
                    <TableCell>Platform</TableCell>
                    <TableCell>Condition</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>Passed-Cases</TableCell>
                    <TableCell>Failed-Cases</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRow.map((row) => (
                    <TableRow
                      key={row.timestamp}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.platform_used}</TableCell>
                      <TableCell>{row.condition}</TableCell>
                      <TableCell>{row.result ? "True" : "False"}</TableCell>
                      <TableCell>
                        {row.passed_cases ? (
                          <div>
                            {row.passed_cases.map((ele) => {
                              return <li>{ele}</li>;
                            })}
                          </div>
                        ) : (
                          " - "
                        )}
                      </TableCell>
                      <TableCell>
                        {row.failed_cases ? (
                          <div>
                            {row.failed_cases.map((ele) => {
                              return <li>{ele}</li>;
                            })}
                          </div>
                        ) : (
                          " - "
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="success" type="submit" onClick={handleClose1}>
              Ok
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  ) : (
    
    <div>
      {/* <div className="headercomp">Diagnostic</div> */}
      <ToastContainer
        toastStyle={{
          fontSize: "13px",
          lineHeight: "20px",
          color: "white",
          backgroundColor: "#e74c3c",
          "--toastify-icon-color-error": "white",
          "--toastify-color-progress-error": "#f0c9c7",
          "--toastify_close-button--light": "white",
          "--toastify_close-button": "white",
        }}
        position="top-right"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          top: "30%",
          left: "46%",
          position: "absolute",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div>
            <MyInputField
              label="VIN"
              type="text"
              placeholder=""
              disabled={false}
              onChange={(e) => {
                setVin(e.target.value);
              }}
              // onKeyPress={handleKeyPress}
              // onEnterButtonKeyUp={() => {
              //     console.log("hi")
              //     handleSearch();
              // }}
              styles={{ width: "100%", cursor: "pointer" }}
              helperText={vinError}
              error={vinError ? true : false}
              fullWidth={false}
            />
          </div>
          <div style={{ marginTop: "23px" }}>
            {/* <p className="input-label">Choose Region</p> */}
            <select
              className="selectbox"
              defaultValue=""
              onChange={handleRadioChange}
            >
              {/* <option value="">Select a Region</option> */}
              <option value="na">NA (North America)</option>
              <option value="eu">EU (Europe)</option>
            </select>
          </div>
          <div style={{ marginTop: "20px" }}>
            {/* <p style={{ textAlign: "start", marginTop: "-3px" }}>
            {" "}
            press{" "}
            <KeyboardReturnIcon
              // {...bindTrigger(popupState)}
              style={{ color: "rgb(131 129 127)", fontSize: "1.3rem",marginBottom:"-6px" }}
            />{" "}
            to submit
          </p> */}
            {showLoader ? (
              <span className="loader" style={{ marginTop: "-3px" }}></span>
            ) : (
              <button
                type="submit"
                style={{
                  borderRadius: "4px",
                  cursor: "pointer",
                  width: "80px",
                  height: "30px",
                  backgroundColor: (env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)"),
                  color: "white",
                  border: 0,
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                }}
                onClick={() => {
                  if (vin === "") {
                    return setVinError("Please Enter Valid VIN");
                  } else {
                    setVinError("");
                    // console.log("User: " + username);
                    getDiagnose();
                    getDevice();
                  }
                }}
                hidden={false}
              >
                Diagnose
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function MyInputField(props) {
  const {
    label,
    type,
    value,
    disabled,
    multiline = false,
    onChange,
    styles,
    required = true,
    helperText = "",
    error = false,
    reference,
    placeholder,
    onClick,
    fullWidth,
    onKeyPress,
  } = props;
  return (
    <>
      <TextField
        ref={reference}
        id={`standard-${label}`}
        required={required}
        disabled={disabled}
        label={label}
        type={type}
        placeholder={placeholder || ""}
        value={value}
        multiline={multiline}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        onKeyPress={() => {
          if (onKeyPress) {
            onKeyPress();
          }
        }}
        style={{
          ...styles,
        }}
        fullWidth={fullWidth}
        margin="normal"
        helperText={helperText}
        error={error}
        variant="outlined"
      />
    </>
  );
}
