import React, { useState } from 'react';
import { Modal, Backdrop, Box, Typography, Button, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const BackdropWithModal = ({ open, onClose, Modal_Title, Content }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = () => {
    const contentText = Content 
      ? Content.map((element, index) => `${index + 1}: ${element.feature}`).join('\n')
      : 'No Data Available';

    navigator.clipboard.writeText(contentText).then(() => {
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 2000);
    });
  };

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Modal
        open={open}
        onClose={onClose} 
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
      >
        <Box
          className="backdrop-with-modal" 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            p: 4,
            overflowY: 'auto',
            border: 'none', 
            boxShadow: 24, 
            borderRadius: 2, 
          }}

        >
          <Button
            variant="contained"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopy}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              zIndex: 10,
              background: "#2522227d"
            }}
          >
            Copy
          </Button>

          <Typography id="modal-title" variant="h6" component="h2" sx={{ mt: 1 }}>
            {Modal_Title}
          </Typography>
          
          <Box sx={{ mt: 1 }}>
            {Content ? (
              Content.map((element, index) => (
                <Typography key={element} sx={{ mt: 1 }}>
                  <strong>{index + 1}:</strong> {element?.feature}
                </Typography>
              ))
            ) : (
              <Typography>No Data Available</Typography>
            )}
          </Box>

          <Snackbar
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            message="Content copied to clipboard!"
            autoHideDuration={2000} 
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
          />
        </Box>
      </Modal>
    </Backdrop>
  );
};

export default BackdropWithModal;
