import { token } from '../config.js'
import { decodeToken } from 'react-jwt';
import dayjs from 'dayjs';
const tokenData = decodeToken(token);

export function getUserName() {
  return tokenData.username;
}

export function checkOperationsAccess() {
  return tokenData['cognito:groups'].includes('operations');
}

export function validateToken() {
  if (tokenData === null) {
    return false;
  }
  else if (tokenData?.exp) {
    return dayjs().unix() < tokenData.exp;
  }
  return false;
}