import React from 'react';
import { Card, Typography } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';

const { Text } = Typography;
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const numberFormatter = (value) => {
  if (value >= 1e6) {
    // Format as millions
    return (value / 1e6).toFixed(2) + 'M';
  } else if (value >= 1e3) {
    // Format as thousands
    return (value / 1e3).toFixed(2) + 'k';
  } else {
    // Return the value as it is if it's less than 1000
    return value.toString();
  }
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        maxRotation: 90, 
        minRotation: 25,
      },
    },
  },
  layout: {
    padding: {
        left: 0,
        right: 0,
        top: 30,
        bottom: 0
    }
  },
  plugins: {
    legend: {
      position: 'bottom'
    },
    datalabels: {
      display: true,
      align: 'top',
      offset: -10,
      color: '#999',
      formatter: (value) => "           " + numberFormatter(value), 
      font: {
        weight: 'bold',
        size: 8,
      },
      rotation: -20
    },
  },
};

const colors = ["#F7A600", "#143A84", "#E32213", "#006D6A", "#EE7C00", "#FF9D6C", "#9FD3CD", "#FDCD50", "#E94E24", "#00AC9F", "#272B35"];
const devices = ['default-NA', 'vp4r-NA', 'r1-NA', 'tbm-NA', 'default-EU']

export default function Trends(props) {

  const processDataForGraph = () => {
    const data = props?.historicData;
    if (data) {
      let labels = [...new Set(data.map(entry => entry.date))].sort();
      const groupedData = {};
      data.forEach(({ date, device, region, count }) => {
        const key = `${device}-${region}`;
        if (!groupedData[key]) {
          groupedData[key] = {};
        }
        let dateString = date;
        groupedData[key][dateString] = count;
      });
      
      const datasets = Object.entries(groupedData).map(([key, dateCounts], i) => {
        const counts = labels.map(date => dateCounts[date] || 0);
        return { label: key, data: counts, borderColor:colors[i], backgroundColor:(colors[i]+'77'), hidden: (!devices.includes(key)) };
      });
      if (props.scope === 'total_unique') {
        labels.forEach((date, i, arr) => arr[i] = dayjs(date).subtract(1,'day').format('MMM-D'));
      }
      else {
        labels.forEach((date, i, arr) => arr[i] = dayjs(date).format('MMM-D'));
      }
      labels.push(dayjs().format('MMM-D'));
      return {
        labels,
        datasets
      };
    }
  }

  return (
    <div>
      <Card style={{border:'1px solid #aaa'}}>
        <div>
          {props.scope === 'total_unique' &&
            <Text strong style={{ padding: '0px 0px 8px 0px' }}>Total unique VINs by device [Last 30 days]</Text>
          }
          {props.scope === 'daily_unique' &&
            <Text strong style={{ padding: '0px 0px 8px 0px' }}>Daily check-ins by device [Last 30 days]</Text>
          }
        </div>
        <div style={{ margin: '8px 8px', height:'70vh', position:'relative', minHeight:'25rem' }}>
          <hr style={{backgroundColor:'#ccc', height:'1px', border:'0'}} />
          <Line options={options} data={processDataForGraph()} />
        </div>
      </Card>
    </div>
  )
}