import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Divider, App, Button, Empty, List, Spin, theme, Tag, Steps, Progress, Space, Popover, Drawer, Row, Col, Input, DatePicker, Tooltip } from 'antd';
import {
    AppstoreAddOutlined, CheckCircleOutlined, DeleteOutlined, LoadingOutlined, SearchOutlined, CloseOutlined, RedoOutlined, 
    CodeOutlined, XOutlined, CheckCircleFilled, CheckOutlined, WarningOutlined, DownloadOutlined, InfoCircleFilled, ClockCircleOutlined, 
} from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone.js';
import advanced from 'dayjs/plugin/advancedFormat.js';
import abbrTimezone from 'dayjs-abbr-timezone';
import { saveAs } from 'file-saver';

import { decodedToken } from '../../config.js';
import { checkOperationsAccess } from '../../utilities/tokenHandlers.js';
import { getUpdatesHistory, getLiveUpdate, getDownloadFile } from '../../utilities/apiHandlers.js';
import { useWindowSize } from '../../utilities/customHooks.js';
import { ReactComponent as ScheduleIcon } from '../../assets/svg/ScheduleIcon.svg'
import { ReactComponent as UnscheduleIcon } from '../../assets/svg/UnscheduleIcon.svg'
import { ReactComponent as RTNIcon } from '../../assets/svg/RTNIcon.svg'
import './UpdateHistory.css';

const { useToken } = theme;
dayjs.extend(relativeTime);
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)
dayjs.extend(abbrTimezone);

export default function UpdateHistory() {
    const { message } = App.useApp();
    const { token } = useToken();
    const [width, height] = useWindowSize();
    const [loading, setLoading] = useState(true);
    const [masterList, setMasterList] = useState([]);
    const [updates, setUpdates] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [ongoingOp, setOngoingOp] = useState(undefined);
    const [downloadPopupIndex, setDownloadPopupIndex] = useState(null);
    const [opDetails, setOpDetails] = useState(undefined);
    const [searching, setSearching] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [deviceFilters, setDeviceFilters] = useState([]);
    const [dateFilter, setDateFilter] = useState(null);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [regionFilter, setRegionFilter] = useState(undefined);
    const [opTypeFilters, setOpTypeFilters] = useState([]);
    // const [deviceMap, setDeviceMap] = useState(null);
    // const [windowSize, setWindowSize] = useState([0,0]);
    // const [cursor, setCursor] = useState(null);
    // const [currentPage, setCurrentPage] = useState(0);

    // Effect to get initial list of updates.
    useEffect(() => {
        let n = 0;
        message.loading('Fetching updates...', 1.5).then(() => {
            if (n > 0) message.success('Done');
        });
        getUpdatesHistory(/*cursor,currentPage*/).then((data) => {
            let sortedOps = ([...data.updates]).sort((a, b) => Number(b.startTime) - Number(a.startTime));
            setUpdates(sortedOps);
            setMasterList(sortedOps);
            setTotalCount(data.total);
            // setCursor(data.cursor || null);
            // setCurrentPage(data.page || 1);
            n = data.updates?.length;
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            message.error('Error fetching updates');
            setLoading(false);
        });
        getLiveUpdate().then((data) => {
            if (data.length > 0) {
                setOngoingOp(data[0]);
                // console.log(data);
                n += 1;
            }
            else {
                setOngoingOp(false);
            }
        }).catch((err) => {
            console.log(err);
            message.error('Error fetching live update');
        });
    }, [message]);

    // Effect to refresh list of updates every 30 seconds.
    // useEffect(() => {
    //   const interval = setTimeout(() => {
    //     let n = 0;
    //     message.loading('Checking for new updates...',1.5).then(() => {
    //       if (n > 0) message.success('Done');
    //     });
    //     getUpdatesHistory().then((data) => {
    //       let sortedOps = ([...data]).sort((a, b) => Number(b.startTime) - Number(a.startTime))
    //       setUpdates(sortedOps);
    //       n = data.length;
    //     }).catch((err) => {
    //       console.log(err);
    //       message.error('Error fetching updates');
    //       setLoading(false);
    //     });
    //     // message.info('Fetching live update...');
    //     getLiveUpdate().then((data) => {
    //       if (data.length > 0) {
    //         n+=1
    //         setOngoingOp(data[0]);
    //       }
    //       else {
    //         setOngoingOp(undefined);
    //       }
    //     }).catch((err) => {
    //       console.log(err);
    //       message.error('Error fetching live update');
    //     });
    //   }, 30000);
    //   return () => clearTimeout(interval);
    // },[message, updates]);

    // Effect to update live progress on ongoing update every 3 seconds.
    useEffect(() => {
        if (ongoingOp) {
            const interval = setInterval(() => {
                // message.loading('Refreshing...',0.5);
                getLiveUpdate().then((data) => {
                    if (data.length > 0) {
                        setOngoingOp(data[0]);
                        // console.log(data)
                    }
                    else {
                        getUpdatesHistory().then((data) => {
                            let sortedOps = ([...data.updates]).sort((a, b) => Number(b.startTime) - Number(a.startTime));
                            // console.log(sortedOps);
                            setUpdates(sortedOps);
                            setMasterList(sortedOps);
                            setTotalCount(data.total);
                            setOngoingOp(undefined);
                            clearInterval(interval);
                        }).catch((err) => {
                            console.log(err);
                            message.error('Error fetching updates');
                        });
                    }
                }).catch((err) => {
                    console.log(err);
                    message.error(<>Error refreshing live status. <br />&emsp; Please refresh the page.</>);
                });
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [message, ongoingOp]);

    const getUpdateDescription = (update) => {
        if ((['update-features', 'delete-features', 'schedule-policy']).includes(update.operation)) {
            return <>{OpDescriptions[update.operation]} <b>{update.inputs.featureName || update.inputs.startupId}</b> on <b>{update.inputs.device}</b> for <b>{update.info.vinCount}</b> VINs</>
        }
        else {
            return <>{OpDescriptions[update.operation]} on <b>{update.inputs.device}</b> for <b>{update.info.vinCount}</b> VINs</>
        }
    }

    const getIconForOp = (op, size='large', color=false) => {
        switch (op) {
            case 'update-features':
                return <AppstoreAddOutlined className={size+'-icon'} style={{ color: (color ? color : token.colorPrimary) }} />
            case 'delete-features':
                return <DeleteOutlined className={size+'-icon'} style={{ color: (color ? color : token.colorError) }} />
            case 'schedule-policy':
                return <ScheduleIcon className={'custom-'+size+'-icon'} style={{ fill: (color ? color : token.colorPrimary) }} />
            case 'unschedule-policy':
                return <UnscheduleIcon className={'custom-'+size+'-icon'} style={{ fill: (color ? color : token.colorWarning) }} />
            case 'prod-remove-features':
                return <RTNIcon className={'custom-'+size+'-icon'} style={{ fill: (color ? color : 'crimson') }} />
            default:
                return <AppstoreAddOutlined className={size+'-icon'} />
        }
    }

    const getOpStepTitles = (currentStage) => {
        return OpStepTitles.map(step => {
            if (step.stage === currentStage) {
                return {
                    ...step, ...(currentStage === 'success' ?
                        { status: 'process', icon: <CheckCircleFilled style={{ color: token.green }} /> } : { status: 'finish', icon: <LoadingOutlined style={{ color: token.colorPrimary }} /> }
                    )
                };
            }
            else {
                return { ...step, status: ((OpStages.indexOf(currentStage) - OpStages.indexOf(step.stage)) > 0 ? 'finish' : 'wait') }
            }
        })
    }

    const getPaginationConfig = () => ({
        position: 'top',
        align: 'end',
        showSizeChanger: true,
        defaultPageSize: (width <= 1540 ? 3 : (width <= 1760 ? 4 : (width <= 2160 ? 5 : 7))),
        pageSizeOptions: [2, 3, 5, 10],
        size: 'small',
        style: { borderBottom: '1px solid #ddd', paddingBottom: '1em', margin: '0 !important' },
        showTotal: (n, r) =>
            <div style={{ width: `calc(100vw - ${(width <= 1480 ? '35rem' : (width <= 1540 ? '35rem' : (width <= 1760 ? '35rem' : (width <= 2160 ? '35rem' : '35rem'))))})`, display: 'flex', justifyContent: 'space-between', fontSize: '1.5em' }}>
                <div style={{ display: 'inline-flex' }}>
                    <div style={{ display: 'inline-block', marginRight: '2vw' }}>
                        <div style={{ fontSize: '0.75rem', lineHeight: '0.75rem', marginTop: '-0.25rem', fontWeight: 'bold' }}><center>Total VINs Processed</center></div>
                        <div style={{ color: token.colorPrimary, textShadow: '1px 1px 0px ' + token.colorPrimary + '22' }}><center>{(ongoingOp ? [ongoingOp, ...masterList] : masterList).reduce((total, opDetails) => (total + opDetails.info.vinCount), 0).toLocaleString()}</center></div>
                    </div>
                    <Divider type='vertical' style={{ height: '2.3rem', marginTop: '-0.4rem' }} />
                    <div style={{ display: 'inline-block', marginLeft: '2vw', marginRight: '2vw' }}>
                        <div style={{ fontSize: '0.75rem', lineHeight: '0.75rem', marginTop: '-0.25rem', fontWeight: 'bold' }}><center>Success Rate</center></div>
                        <div style={{ color: token.green7 }}><center>{
                            (((masterList || []).reduce((total, opDetails) => (total + opDetails.info.successful), 0) /
                                (masterList || []).reduce((total, opDetails) => (total + opDetails.info.vinCount), 0)) * 100).toFixed(1)
                        }%</center></div>
                    </div>
                    <Divider type='vertical' style={{ height: '2.3rem', marginTop: '-0.4rem' }} />
                    {(searching) && <>
                        <div style={{ display: 'inline-block', marginLeft: '2vw', marginRight: '2vw' }}>
                            <div style={{ fontSize: '0.75rem', lineHeight: '0.75rem', marginTop: '-0.25rem', fontWeight: 'bold' }}><center>Filtered VINs Total</center></div>
                            <div><center>{
                                (ongoingOp ? [ongoingOp, ...updates] : updates).reduce((total, opDetails) => (total + opDetails.info.vinCount), 0).toLocaleString()
                            }</center></div>
                        </div>
                        <Divider type='vertical' style={{ height: '2.3rem', marginTop: '-0.4rem' }} />
                    </>}
                    {(!searching) && <>
                        <div style={{ display: 'inline-block', marginLeft: '2vw', marginRight: '2vw' }}>
                            <div style={{ fontSize: '0.75rem', lineHeight: '0.75rem', marginTop: '-0.25rem', fontWeight: 'bold' }}><center>Last 30 Days</center></div>
                            <div><center>{
                                (ongoingOp ? [ongoingOp, ...masterList] : masterList).filter((op) => dayjs(Number(op.startTime)).isAfter(dayjs().subtract(30, 'day'))).reduce((total, opDetails) => (total + opDetails.info.vinCount), 0).toLocaleString()
                            }</center></div>
                        </div>
                        <Divider type='vertical' style={{ height: '2.3rem', marginTop: '-0.4rem' }} />
                    </>}
                    {(!searching && checkOperationsAccess()) && <>
                        <div style={{ display: 'inline-block', marginLeft: '2vw', marginRight: '2vw' }}>
                            <div style={{ fontSize: '0.75rem', lineHeight: '0.75rem', marginTop: '-0.25rem', fontWeight: 'bold' }}><center>By Me</center></div>
                            <div><center>{
                                (ongoingOp ? [ongoingOp, ...masterList] : masterList).reduce((total, opDetails) => (total + (opDetails.user?.username === decodedToken?.username ? 1 : 0)), 0)
                            }</center></div>
                        </div>
                        <Divider type='vertical' style={{ height: '2.3rem', marginTop: '-0.4rem' }} />
                    </>}
                    {searching && 
                        <div style={{ display: 'inline-block', marginLeft: '2vw', marginRight: '2vw', color:'#999', fontSize:'1rem' }}>
                            <i>Found <b>{updates.length}</b> results</i>
                        </div>
                    }
                </div>
                <div style={{ display: 'inline-flex', fontSize:'1rem' }}>Viewing {(r[0] + "-" + r[1] + " of " + n)}</div>
            </div>
    })

    const getDeviceList = () => {
        let map = null;
        if (!map && masterList.length > 0) {
            map = {};
            let opsList = (ongoingOp ? [ongoingOp, ...masterList] : masterList);
            for (let i = 0; i < opsList.length; i++) {
                if (!(opsList[i].inputs.device in map)) {
                    map[opsList[i].inputs.device] = [opsList[i].startTime];
                }
                else {
                    map[opsList[i].inputs.device].push(opsList[i].startTime)
                }
            }
            // setDeviceMap({ ...map });
        }
        return <>
            {Object.keys(map).map(device => (
                <Tag.CheckableTag
                    onChange={(checked) => {
                        // setLoading(true);
                        let selectedDevices = checked
                            ? [...deviceFilters, device]
                            : deviceFilters.filter((t) => t !== device);
                        setDeviceFilters([...selectedDevices]);
                    }}
                    key={device}
                    checked={deviceFilters.includes(device)}
                    style={{ 
                        textTransform: (device === 'default' ? 'capitalize' : 'uppercase'), 
                        border: (deviceFilters.includes(device) ? 'unset' : '0.75px solid #aaa'),
                        ...(!deviceFilters.includes(device) && {backgroundColor:'white'})
                    }}
                >
                    {device}
                </Tag.CheckableTag>
            ))}
            <Tooltip title={<small>Clear Device Filters</small>} >
                <Button 
                    shape='circle' 
                    size='small' 
                    icon={<CloseOutlined/>} 
                    style={{marginLeft:'0.25em', transition:'all 0.2s ease-in-out', visibility:(deviceFilters.length === 0 ? 'hidden' : 'visible')}}  
                    disabled={deviceFilters.length === 0}
                    onClick={() => {
                        // setLoading(true);
                        setDeviceFilters([]);
                    }}
                />
            </Tooltip>
        </>
    }

    const getDateFilters = () => {
        // Return checkable tags for 1W, 1M and custom date input
        return <>
            {(dateFilter!== 'Custom' || width > 1760) && <>
                <Tag.CheckableTag
                    onChange={(checked) => {setDateFilter(checked ? '1wk' : null) }}
                    key={'1wk'}
                    checked={dateFilter === '1wk'}
                    style={{ border: (dateFilter === '1wk' ? 'unset' : '0.75px solid #aaa'), ...(dateFilter !== '1wk' && {backgroundColor:'white'})}}
                >
                    1 wk
                </Tag.CheckableTag>
                <Tag.CheckableTag
                    onChange={(checked) => {setDateFilter(checked ? '1mo' : null)}}
                    key={'1mo'}
                    checked={dateFilter === '1mo'}
                    style={{ border: (dateFilter === '1mo' ? 'unset' : '0.75px solid #aaa'), ...(dateFilter !== '1mo' && {backgroundColor:'white'})}}
                >
                    1 mo
                </Tag.CheckableTag>
            </>}
            {(dateFilter!== 'Custom' || width > 1480) && 
                <Tag.CheckableTag
                    onChange={(checked) => {setDateFilter(checked ? 'Custom' : null); if (!checked) {setStartDate(null); setEndDate(null);}}}
                    key={'Custom'}
                    checked={dateFilter === 'Custom'}
                    style={{ border: (dateFilter === 'Custom' ? 'unset' : '0.75px solid #aaa'), transition:'all 0.2s ease-in-out', ...(dateFilter !== 'Custom' && {backgroundColor:'white'})}}
                >
                    Custom
                </Tag.CheckableTag>
            }
            {(dateFilter === 'Custom') && <>
                <DatePicker.RangePicker
                    allowClear={false}
                    value={startDate ? [dayjs(startDate), dayjs()] : null}
                    disabled={dateFilter !== 'Custom'}  
                    onChange={(dates) => {
                        if (dates) {
                            // setLoading(true);
                            setStartDate(dates[0].valueOf());
                            setEndDate(dates[1].valueOf());
                            // console.log(dates);
                        }
                        else {
                            // setLoading(true);
                            setStartDate(null);
                            setEndDate(null);
                        }
                    }}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                    style={{ width: '12rem', backgroundColor:'white' }}
                    size='small'
                />
                <Tooltip title={<small>Clear dates</small>} >
                    <Button 
                        shape='circle' 
                        size='small' 
                        icon={<RedoOutlined/>} 
                        style={{marginLeft:'0.5em', transition:'all 0.2s ease-in-out', visibility:(!startDate || !endDate ? 'hidden' : 'visible')}}
                        disabled={!startDate || !endDate}
                        onClick={() => {
                            // setLoading(true);
                            setStartDate(null);
                            setEndDate(null);
                        }}
                    />
                </Tooltip>
            </>}
            <Tooltip title={<small>Exit date select mode</small>} >
                <Button 
                    shape='circle' 
                    size='small' 
                    icon={<CloseOutlined/>} 
                    style={{marginLeft:'0.25em', transition:'all 0.2s ease-in-out', visibility:(!dateFilter ? 'hidden' : 'visible')}}  
                    onClick={() => {
                        // setLoading(true);
                        setDateFilter(null);
                        setStartDate(null);
                        setEndDate(null);
                    }}
                />
            </Tooltip>
        </>
    }

    const getRegionFilters = () => {
        // Return checkable tags for na, eu
        return <>
            <Tag.CheckableTag
                onChange={(checked) => {setRegionFilter(checked?'na':null) }}
                key={'na'}
                checked={regionFilter === 'na'}
                style={{ border: (regionFilter === 'na' ? 'unset' : '0.75px solid #aaa'), ...(regionFilter !== 'na' && {backgroundColor:'white'})}}
            >
                NA
            </Tag.CheckableTag>
            <Tag.CheckableTag
                onChange={(checked) => {setRegionFilter(checked?'eu':null) }}
                key={'eu'}
                checked={regionFilter === 'eu'}
                style={{ border: (regionFilter === 'eu' ? 'unset' : '0.75px solid #aaa'), ...(regionFilter !== 'eu' && {backgroundColor:'white'})}}
            >
                EU
            </Tag.CheckableTag>
            <Tooltip title={<small>Clear Region Filter</small>} >
                <Button 
                    shape='circle' 
                    size='small' 
                    icon={<CloseOutlined/>} 
                    style={{marginLeft:'0.25em', transition:'all 0.2s ease-in-out', visibility:(!regionFilter ? 'hidden' : 'visible')}}  
                    onClick={() => {
                        setRegionFilter(null);
                    }}
                />
            </Tooltip>
        </>
    }

    const getUpdateTypesFilters = () => {
        return <>
            {Object.keys(OpLabels).map(op =>
                <Tooltip title={OpLabels[op]} key={op}>
                    <Tag.CheckableTag
                        onChange={(checked) => {
                            let selectedOpTypes = checked
                                ? [...opTypeFilters, op]
                                : opTypeFilters.filter((t) => t !== op);
                            setOpTypeFilters([...selectedOpTypes]);
                        }}
                        checked={opTypeFilters.includes(op)}
                        style={{ border: (opTypeFilters.includes(op) ? 'unset' : '0.75px solid #aaa'), ...(!opTypeFilters.includes(op) && {backgroundColor:'#eee'})}}
                    >
                        {getIconForOp(op, 'small', (opTypeFilters.includes(op) ? 'white' : token.colorPrimary))}
                    </Tag.CheckableTag>
                </Tooltip>
            )}
            <Tooltip title={<small>Clear Filters</small>} >
                <Button 
                    shape='circle' 
                    size='small' 
                    icon={<CloseOutlined/>} 
                    style={{marginLeft:'0.25em', transition:'all 0.2s ease-in-out', visibility:(opTypeFilters.length === 0 ? 'hidden' : 'visible')}}  
                    onClick={() => {
                        setOpTypeFilters([]);
                    }}
                />
            </Tooltip>
        </>
    }

    useEffect(() => {
        // console.log('Filtering updates', deviceFilters, dateFilter, searchText, startDate, endDate);
        let filteredUpdates = [];
        let searchActive = false
        if (deviceFilters.length > 0) {
            filteredUpdates = (ongoingOp ? [ongoingOp, ...masterList] : masterList).filter((op) => deviceFilters.includes(op.inputs.device));
            searchActive = true;
        }
        else {
            filteredUpdates = [...masterList];
        }
        if (dateFilter) {
            if (dateFilter === '1wk') {
                filteredUpdates = filteredUpdates.filter((op) => dayjs(Number(op.startTime)).isAfter(dayjs().subtract(7, 'day')));
                searchActive = true;
            }
            else if (dateFilter === '1mo') {
                filteredUpdates = filteredUpdates.filter((op) => dayjs(Number(op.startTime)).isAfter(dayjs().subtract(30, 'day')));
                searchActive = true;
            }
            else if (dateFilter === 'Custom') {
                if (startDate && endDate) {
                    filteredUpdates = filteredUpdates.filter((op) => dayjs(Number(op.startTime)).isAfter(dayjs(startDate)) && dayjs(Number(op.startTime)).isBefore(dayjs(endDate)));
                    searchActive = true;
                }
            }
        }
        if (regionFilter) {
            filteredUpdates = filteredUpdates.filter((op) => op.region === regionFilter);
            searchActive = true;
        }
        if (opTypeFilters.length > 0) {
            filteredUpdates = filteredUpdates.filter((op) => opTypeFilters.includes(op.operation));
            searchActive = true;
        }
        if (searchText.length > 0) {
            let filteredByText = [];
            searchActive = true;
            let searchWrods = searchText.trim().split(' ');
            for (let i=0; i < filteredUpdates.length; i++){
                for (let word of searchWrods) {
                    let match = false;
                    let { filename, region, inputs, user } = filteredUpdates[i];
                    let inputsString = '', userString = '';
                    Object.values(inputs).forEach((val) => inputsString += (" " + val));
                    Object.values(user).forEach((val) => userString += (" " + val));
                    if ((filename + " " + region).toLowerCase().includes(word.toLowerCase())) {
                        match = true;
                    }
                    else if (inputsString.toLowerCase().includes(word.toLowerCase())) {
                        match = true;
                    }
                    else if (userString.toLowerCase().includes(word.toLowerCase())) {
                        match = true;
                    }
                    if (match) {
                        filteredByText.push(filteredUpdates[i]);
                        break;
                    }
                }
            }
            filteredUpdates = [...filteredByText];
        }
        // console.log(deviceFilters)
        setUpdates(filteredUpdates);
        setSearching(searchActive);
        // setLoading(false);
    }, [deviceFilters, dateFilter, regionFilter, opTypeFilters, searchText, startDate, endDate])

    const downloadFile = (filepath) => {
        setDownloadPopupIndex(null);
        getDownloadFile({ file: filepath }).then(data => {
            let filename = filepath.split('/').at(-1);
            let fileContents;
            if (filename.includes(".json")) {
                fileContents = JSON.stringify(data, null, 4);
            }
            else if (filename.includes("failed_vins.txt")) {
                filename = filename.replace('failed_vins.txt', 'retry.csv')
                fileContents = data;
            }
            const blob = new Blob([fileContents], { type: "text/plain;charset=utf-8" });
            saveAs(blob, filename);
            message.info("Downloaded " + filename)
        }).catch(err => {
            message.error('Error while downloading file. Check connection and refresh the page.')
        });
    }

    useLayoutEffect(() => {
        function updateSize() {
            setLoading(true);
            setTimeout(() => setLoading(false), 200);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <div>
            {loading &&
                <Spin size="large" tip="Loading Updates..." fullscreen={true} />
            }
            {!loading && masterList.length === 0 && !ongoingOp &&
                <Empty description='No updates to show' style={{ marginTop: '5em' }} />
            }
            {!loading && (masterList.length > 0 || ongoingOp) &&
                <div className='opsListContainer'>
                    <div id='search-and-filter' style={{padding: (width <= 1480 ? '0.75em' : '1em') + ' 1.5em'}}>
                        <Row>
                            <Col style={{display:'flex',flexGrow:'1'}}>
                                <Tooltip title={<div><b>Hint</b><small><br/>You can combine multiple search terms<br/>Just separate them with spaces<br/>Eg: "evas T0140EE "</small></div>} >
                                    <Input
                                        prefix={<SearchOutlined style={{ marginRight: '0.5em' }} />}
                                        placeholder="Search for feature name, source, filename, user, etc"
                                        value={searchText}
                                        style={{height:'2.1rem'}}
                                        onChange={(e) => {
                                            setSearchText(e.target.value);
                                            setSearching(Boolean(e.target.value.length))
                                        }}
                                    />
                                </Tooltip>
                            </Col>
                            <Divider type='vertical' style={{ height: '2rem' }} />
                            <Col>
                                <div style={{ marginBottom:'0.5em',border: '1px solid '+(deviceFilters.length ? token.orange2 : token.orange1), backgroundColor: (deviceFilters.length ? token.orange3 : token.orange1), transition:'all 0.2s ease-in-out', borderRadius: '0.5rem', padding: '0.25em 1em' }}>
                                    Devices
                                    <Divider type='vertical' style={{ height: '1.5rem', margin: 'auto 1rem' }} />
                                    {getDeviceList()}
                                </div>
                            </Col>
                            <Divider type='vertical' style={{ height: '2rem' }} />
                            <Col>
                                <div style={{ marginBottom:'0.5em',border: '1px solid '+(dateFilter ? token.yellow3 : token.yellow2), backgroundColor: ((['1wk','1mo'].includes(dateFilter) || (startDate && endDate)) ? token.yellow3 : token.yellow1), transition:'all 0.2s ease-in-out', borderRadius: '0.5rem', padding: '0.25em 1em' }}>
                                    Date
                                    <Divider type='vertical' style={{ height: '1.5rem', margin: 'auto 1rem' }} />
                                    {getDateFilters()}
                                </div>
                            </Col>
                            <Divider type='vertical' style={{ height: '2rem' }} />
                            <Col>
                                <div style={{ border: '1px solid '+(regionFilter ? token.blue2 : token.blue1), backgroundColor: (regionFilter ? token.blue2 : token.blue1), transition:'all 0.2s ease-in-out', borderRadius: '0.5rem', padding: '0.25em 1em' }}>
                                    Region
                                    <Divider type='vertical' style={{ height: '1.5rem', margin: 'auto 1rem' }} />
                                    {getRegionFilters()}
                                </div>
                            </Col>
                            <Divider type='vertical' style={{ height: '2rem' }} />
                            <Col>
                                <div style={{ border: '1px solid '+(opTypeFilters.length ? token.red2 : token.red1), backgroundColor: (opTypeFilters.length ? token.red2 : token.red1), transition:'all 0.2s ease-in-out', borderRadius: '0.5rem', padding: '0.25em 1em' }}>
                                    Op Type
                                    <Divider type='vertical' style={{ height: '1.5rem', margin: 'auto 1rem' }} />
                                    {getUpdateTypesFilters()}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <List
                        bordered
                        pagination={getPaginationConfig()}
                        total={totalCount}
                        itemLayout='horizontal'
                        dataSource={ongoingOp ? [ongoingOp, ...updates] : updates}
                        renderItem={(item, index) => (
                            <List.Item className='update-row'
                                actions={[
                                    <Space direction='vertical' size={3}>
                                        <Button type='primary' disabled={item.status === 'ongoing'} size='small' onClick={() => { setOpDetails(item); setDownloadPopupIndex(null); }}><InfoCircleFilled /> Details</Button>
                                        <Popover
                                            open={downloadPopupIndex === index}
                                            placement='left'
                                            trigger='click'
                                            title={
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>Download Files</div>
                                                    <Button size='small' onClick={() => setDownloadPopupIndex(null)}>×</Button>
                                                </div>
                                            }
                                            content={
                                                <table style={{ lineHeight: '1.5rem', fontSize: '1.15em', minWidth: '15rem' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>List of failed VINs [Retry File]</td>
                                                            <td><Button size='small' type='primary' disabled={item.info.failed === 0} onClick={() => downloadFile(item.files.failedList)}><DownloadOutlined />.csv</Button></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Failure Report</td>
                                                            <td><Button size='small' type='primary' disabled={item.info.failed === 0} onClick={() => downloadFile(item.files.failed)}><DownloadOutlined />.json</Button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }
                                        >
                                            <Button type='primary' disabled={item.status === 'ongoing'} size='small' onClick={() => setDownloadPopupIndex(index)}><DownloadOutlined />Downloads</Button>
                                        </Popover>
                                    </Space>
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={item.status === "ongoing" ?
                                        <center className='status-wrapper'>
                                            {/* <LoadingOutlined className='large-icon' style={{color: token.colorPrimary}} /> */}
                                            <Spin style={{ margin: '0rem auto 0.75rem' }} size='large' />
                                            <div className='status-label'>Ongoing</div>
                                        </center> :
                                        <center className='status-wrapper'>
                                            <CheckCircleOutlined className='large-icon' style={{ color: token.colorSuccess }} />
                                            <div className='status-label'>Completed</div>
                                        </center>
                                    }
                                    style={{ maxWidth: '4.5rem' }}
                                />
                                <Divider type="vertical" style={{ height: '6em' }} />
                                <div className='op-label'>
                                    {getIconForOp(item.operation)}
                                    <div className='status-label'>{OpLabels[item.operation]}</div>
                                </div>
                                <Divider type="vertical" style={{ height: '6em' }} />
                                <div className='inputs-section'>
                                    &nbsp;
                                    <span className={`region-label ${item.region === 'na' && 'active'}`}>NA</span> <b>|</b> <span className={`region-label ${item.region === 'eu' && 'active'}`}>EU</span>
                                    <Divider className='inputs-divider' />
                                    &nbsp;<b style={{ color: token.colorPrimary }}>{item.info.vinCount}</b> VINs
                                    <Divider className='inputs-divider' />
                                    <Tag color={token['blue-6']} style={{ fontWeight: '500', textTransform: (item.inputs.device === 'default' ? 'capitalize' : 'uppercase') }}>
                                        <CodeOutlined style={{ marginRight: '0.25em', fontSize: '1.1em' }} />
                                        {item.inputs.device}
                                    </Tag>
                                </div>
                                <Divider type="vertical" style={{ height: '6em' }} />
                                <div className='details-section'>
                                    <div className='update-time'>
                                        <ClockCircleOutlined style={{ color: 'grey', margin: '0 0.5rem 0 0.25rem' }} />
                                        {dayjs(Number(item.startTime)).fromNow()} <span style={{ color: '#999', fontWeight: '400' }}>({dayjs(Number(item.startTime)).tz(dayjs.tz.guess()).format("DD MMM 'YY | hh:mm a | t")})</span>
                                    </div>
                                    <div className='update-filename'>
                                        <Tooltip title={item.filename}>
                                            <Tag color='#10793F' style={{ marginTop:'0.5em', fontSize: '0.9em', fontWeight: '500', opacity: '0.9', maxWidth:(width>1480 && width<1600 ? '20rem' : '27rem'), overflow:'hidden', textOverflow:'ellipsis', cursor:'default' }}>
                                                <XOutlined style={{ marginRight: '0.25em' }} /> {item.filename}
                                            </Tag>
                                        </Tooltip>
                                    </div>
                                    <div className='update-description'>{getUpdateDescription(item)}</div>
                                </div>
                                {/* <div className='steps-progress'>
                                    <Divider type="vertical" className='steps-divider' />
                                    <Progress 
                                        type='dashboard'                    
                                        strokeLinecap='butt'
                                        percent={75}
                                        size={80}
                                        strokeWidth={10}
                                        gapDegree={180}
                                        trailColor="rgba(0, 0, 0, 0.06)"
                                        style={{margin: '0 1rem'}} 
                                        strokeColor={token.colorPrimary}
                                        steps={{
                                        count: 4,
                                        gap: 3
                                        }}
                                        success={{ percent: 25, strokeColor: token.lime }}
                                    />
                                </div> */}
                                <div className='steps-wrapper'>
                                    <Divider type="vertical" className='steps-divider' />
                                    <Steps
                                        current={OpStepTitles.findIndex(step => step.stage === item.stage)}
                                        items={getOpStepTitles(item.stage)}
                                        labelPlacement='vertical'
                                        size='small'
                                        className='steps-component'
                                    />
                                </div>
                                <Divider type="vertical" style={{ height: '6em' }} />
                                <Progress
                                    type='dashboard'
                                    strokeLinecap='butt'
                                    percent={item.info.progress}
                                    strokeColor={item.status === 'ongoing' ? token.colorPrimary : token['red-5']}
                                    success={{
                                        percent: (item.status === 'ongoing' ? item.info.progress : (item.info.successful / item.info.vinCount) * 100),
                                        strokeColor: token.lime
                                    }}
                                    size={80}
                                    format={(p) => {
                                        if (p === 100) return (
                                            <div style={{ fontWeight: '500' }}>
                                                <small style={{ color: token.lime }}><CheckOutlined style={{ fontSize: '0.85em', marginRight: '0.25em' }} />{item.info.successful}</small>
                                                {item.info.failed > 0 && <><br /><small style={{ color: token['red-5'] }}><WarningOutlined style={{ fontSize: '0.85em', marginRight: '0.5em' }} />{item.info.failed}</small></>}
                                            </div>
                                        )
                                        else return (p + '%')
                                    }}
                                    style={{ margin: '0 1rem' }}
                                />
                                <Divider type="vertical" style={{ height: '6em' }} />
                            </List.Item>
                        )}
                        style={{ borderColor: '#ddd' }}
                    />

                </div>
            }
            <Drawer
                title="Update Details"
                placement='right'
                // size='large'
                open={opDetails}
                onClose={() => setOpDetails(undefined)}
            >
                {opDetails &&
                    <div style={{ fontSize: '1rem' }}>
                        <small>Started by</small><br />
                        <b>{opDetails.user.username}</b>
                        <br /><br />
                        <small>VINs</small><br />
                        <b style={{ color: token.colorPrimary }}>{opDetails.info.vinCount}</b>
                        <br /><br />
                        <small>Successful Updates</small><br />
                        <b style={{ color: token.colorSuccess }}>{opDetails.info.successful}</b>
                        <br /><br />
                        <small>Failed Updates</small><br />
                        <b style={{ color: token.colorError }}>{opDetails.info.failed}</b>
                        <br /><br />
                        {opDetails.inputs.featureName &&
                            <>
                                <small>Feature Name</small><br />
                                <b>{opDetails.inputs.featureName}</b>
                                <br /><br />
                            </>
                        }
                        {opDetails.inputs.startupId &&
                            <>
                                <small>Schedule Policy ID</small><br />
                                <b>{opDetails.inputs.startupId}</b>
                                <br /><br />
                            </>
                        }
                        {opDetails.inputs.source &&
                            <>
                                <small>Source</small><br />
                                <b>{opDetails.inputs.source}</b>
                                <br /><br />
                            </>
                        }
                        {opDetails.inputs.expiryDate &&
                            <>
                                <small>Source</small><br />
                                <b>{opDetails.inputs.expiryDate}</b>
                                <br /><br />
                            </>
                        }
                    </div>
                }
            </Drawer>
        </div>
    )
}

const OpLabels = {
    'update-features': 'Add Feature',
    'delete-features': 'Remove feature',
    'schedule-policy': 'Schedule Policy',
    'unschedule-policy': 'Unscheduled policy',
    'prod-remove-features': 'Return To New'
};
const OpDescriptions = {
    'update-features': 'Add feature',
    'delete-features': 'Remove feature',
    'prod-remove-features': 'Do an RTN',
    'schedule-policy': 'Schedule policy',
    'unschedule-policy': 'Remove scheduled policy'
};
const OpStages = ['preprocessing', 'processing', 'aggregating', 'success'];
const OpStageLabels = {
    'preprocessing': 'Preprocessing',
    'processing': 'Processing',
    'aggregating': 'Aggregating',
    'success': 'Success'
};
const OpStepTitles = Object.keys(OpStageLabels).map((stage) => ({
    title: OpStageLabels[stage],
    status: (stage === 'success' ? 'finish' : 'process'),
    stage: stage
}))