// import { useState } from 'react';
import { Navigate, Routes, useLocation } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { ConfigProvider, App as AntDApp } from 'antd';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar.js'
import Home from './components/Home/Home.js';
import NewUpdate from './components/NewUpdate/NewUpdate.js';
import UpdateHistory from './components/UpdateHistory/UpdateHistory.js';
import BatchAnalysis from './components/BatchAnalysis/BatchAnalysis.js';
import { checkOperationsAccess, validateToken } from './utilities/tokenHandlers.js';
import { adaWebURL, env } from './config.js';
import Diagnostic from './components/Diagnostic/Diagnostic.js'

function App() {
  const location = useLocation();
  const kickOut = () => {
    // console.log(validateToken());
    alert('You need to login to ADA...');
    window.location.href = adaWebURL;
  }
  return (
    <>
      {validateToken() ?
        <div className="App">
          <Sidebar />
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: 'rgb(36, 55, 130)',
                colorBorder: '#ccc',
                colorTextPlaceholder: '#888',
                colorSplit: '#1114',
                borderRadius: 4
              }
            }}
          >
            <AntDApp>
              <div id='viewport'>
                {
                  (pageTitles[location.pathname]) == 'Diagnostic' ? '' :
                    <div className="header-component">
                      {pageTitles[location.pathname]}
                    </div>
                }
                <div id='main-content'>
                  <Routes>
                    <Route exact path="/" element={<Navigate to='/home' />} />
                    <Route exact path="/home" element={<Home />} />
                    {checkOperationsAccess() && <Route exact path="/new-update" element={<NewUpdate />} />}
                    <Route exact path="/update-history" element={<UpdateHistory />} />
                    <Route exact path="/batch-analysis" element={<BatchAnalysis />} />
                    {env !== 'prod' && <Route exact path="/diagnostic" element={<Diagnostic />} />}
                  </Routes>
                </div>
              </div>
            </AntDApp>
          </ConfigProvider>
        </div>
        :
        <>{kickOut()}</>
      }
    </>
  );
}

export default App;

const pageTitles = {
  "/home": "Operations Dashboard",
  "/new-update": "New Batch Update",
  "/update-history": "Batch Update History",
  "/batch-analysis": "Batch Analysis",
  "/diagnostic": "Diagnostic"
}