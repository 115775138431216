import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import './Sidebar.css'; // Import the CSS file for styling
import { CloudSyncOutlined, BarChartOutlined } from '@ant-design/icons';
import { ReactComponent as PersonIcon } from '../../assets/svg/PersonIcon.svg'
import Cookies from 'js-cookie';
import { checkOperationsAccess } from '../../utilities/tokenHandlers.js';
import { LogoutOutlined } from '@ant-design/icons'
import { adaWebURL, cookieName, adaWebDomain, env } from '../../config.js';

function Sidebar() {
  const [showBatchOpsText, setShowBatchOpsText] = useState(false)
  const [showDashbordText, setShowDashbordText] = useState(false)
  const [showDiaName, setShowDiaName] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const navigate = useNavigate();
  const onLogout = () => {
    Cookies.remove(cookieName, { domain: adaWebDomain, path:'/' });
    window.location = adaWebURL;
  };
  return (
    <div>
      <div className="main_sidebar">
        <div className="sidebar">
          <div className="firstdiv">
            {env === 'dev' &&
              <div className="dev" style={{backgroundColor:(env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)")}}>
                <p className="dev_p" >dev</p>
              </div>
            }
            <div>
              <a style={{ "textDecoration": "none" }} href={adaWebURL}>
                <p className="app_name" style={{ "cursor": "pointer", 'color':(env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)"), 'textShadow':`${(env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)")} 0.125rem 0px` }}>ada</p>
              </a>
            </div>
          </div>
          <div className="seconddiv">
            <div className="nav-list">
              <div style={{ 'textAlign': 'center', 'cursor': 'pointer',paddingBottom:'25px' }} onClick={() => navigate('/home')} onMouseEnter={() => setShowDashbordText(true)} onMouseLeave={() => { setShowDashbordText(false); setShowPopup(false); }}>
                {/* <img src={{SystemUpdateAltIcon}} className="icon"   /> */}
                <BarChartOutlined className="icon" style={{ color: (env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)"), "fontSize": "2.4rem" }} />
                {showDashbordText ? <p className="iconName">Dashboard</p> : ''}
              </div>
              <div style={{ 'textAlign': 'center', 'cursor': 'pointer',paddingBottom:'25px' }} onClick={() => navigate('/update-history')} onMouseEnter={() => setShowBatchOpsText(true)} onMouseLeave={() => { setShowBatchOpsText(false); setShowPopup(false); }}>
                {/* <img src={{SystemUpdateAltIcon}} className="icon"   /> */}
                <CloudSyncOutlined className="icon" style={{ color: (env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)"), "fontSize": "2.4rem" }} />
                {showBatchOpsText ? <p className="iconName">Batch Ops</p> : ''}
              </div>
              {env !== 'prod' &&
                <div style={{ 'textAlign': 'center', 'cursor': 'pointer' }} onClick={() => navigate('/diagnostic')} onMouseEnter={() => setShowDiaName(true)} onMouseLeave={() => { setShowDiaName(false);}} >
                  {/* <img src={{SystemUpdateAltIcon}} className="icon"   /> */}
                  <LogoutOutlined className="icon" style={{ color: (env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)"), "fontSize": "2.1rem" }} />
                  {showDiaName ? <p className="iconName">Diagnostic</p> : ''}
                </div>
              }
            </div>
          </div>
          <div className="thirddiv" >
            <div style={{ 'textAlign': 'center', marginBottom: '18px', 'zIndex': (showPopup ? '0' : '10') }}>
              <p className="app_name" style={{ "cursor": "pointer", 'color':(env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)"), 'textShadow':`${(env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)")} 0.125rem 0px` }} onClick={() => { navigate('/home'); }}>ops</p>
            </div>
            <div style={{ 'textAlign': 'center', 'position': 'relative', 'zIndex': '5' }}>
              <div id='logout-popup' style={{ 'opacity': (showPopup ? '1' : '0'), 'zIndex': (showPopup ? '10' : '0') }} onClick={onLogout}>Logout</div>
              <PersonIcon onClick={() => setShowPopup(!showPopup)} className="icon" style={{ 'fill': (env==='prod'?"rgb(67, 170, 160)":"rgb(236, 169, 53)"), "fontSize": "2.4rem" }} />
            </div>
          </div>

        </div>
      </div>

      {showBatchOpsText ? <div className="sub_division" onMouseEnter={() => setShowBatchOpsText(true)} onMouseLeave={() => { setShowBatchOpsText(false); setShowPopup(false); }}>
        <div className="sub_item" >
          {checkOperationsAccess('operations') &&
            <div style={{ "width": "95%", "height": "2.813rem", "cursor": "pointer", "borderRadius": "1.875rem" }}>
              <span className="item" onClick={() => { navigate('/new-update'); }} >New Update</span>
            </div>
          }
          <div style={{ "width": "95%", "height": "2.813rem", "cursor": "pointer", "borderRadius": "1.875rem" }}>
            <span className="item" onClick={() => { navigate('/update-history'); }}>Update History</span>
          </div>
          <div style={{ "width": "95%", "height": "2.813rem", "cursor": "pointer", "borderRadius": "1.875rem" }}>
            <span className="item" onClick={() => { navigate('/batch-analysis'); }}>Batch Analysis</span>
          </div>
        </div>
      </div> : <div className="sub_division_hidden"></div>}
    </div>

  );
}

export default Sidebar;
