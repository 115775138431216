// import {useEffect, useState} from 'react';
import GraphComponent from "../GraphComponent/GraphComponent";
// import moment from 'moment';
import { Card, Typography } from 'antd';
import "./DashboardGraphCard.css";

const { Text } = Typography;

// const graphList = ['total_vins', 'total_feature', 'top_5_brands_using_ada', 'device_vehicle_count', 
//     'top_10_models_using_ada', 'vehicle_count_by_countries', 'top_10_feature_on_vp4r',
//      'top_10_feature_on_r1', 'top_10_feature_on_tbm'];

function DashboardGraphCard(propData) {
  // function getStartDate(){
  //     return moment().subtract(1, 'days').format('YYYY-MM-DDThh:mm');
  // }

  // function getEndDate(){
  //     return moment().format('YYYY-MM-DDThh:mm');
  // }

  function processDataForGraph() {
    let labelArray = [];
    let dataArray = [];

    let passedGraphData = propData?.graphData;

    if (passedGraphData) {
      passedGraphData.forEach((item) => {
        labelArray.push(item[propData.labelTag]);
        dataArray.push(item.count);
      });
    }
    // console.log('label', labelArray);
    return {
      labels: labelArray,
      datasets: [
        {
          label: '',
          backgroundColor: ["#FF9D6C", "#9FD3CD", "#FDCD50", "#E94E24", "#00AC9F", "#F7A600", "#143A84", "#E32213", "#006D6A", "#EE7C00", "#272B35"
          ],
          borderColor: [
            "#FF9D6C", "#9FD3CD", "#FDCD50", "#E94E24", "#00AC9F", "#F7A600", "#143A84", "#E32213", "#006D6A", "#EE7C00", "#272B35"
          ],
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)',
          hoverBorderColor: 'rgba(255, 99, 132, 1)',
          data: dataArray,
        },
      ],
    };
  }

  return (
    <Card style={{ margin: '8px 8px', border:'1px solid #aaa' }}>
      <div className="graph-name">
        <Text strong style={{ padding: '0px 0px 8px 0px' }}>{propData.graphName}</Text>
      </div>
      <div>
        <GraphComponent name={propData.graphName} data={processDataForGraph()} />
      </div>
    </Card>
  );
}

export default DashboardGraphCard;