import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { Select, Upload, Radio, Divider, Input, Button, DatePicker, App, Popconfirm, Result, Spin } from 'antd';
import { FileExcelOutlined, CheckCircleFilled, LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { getFeature, getLiveUpdate, getPolicy, getVINProfile, startNewUpdate } from '../../utilities/apiHandlers.js';
import './NewUpdate.css';
import { getUserName } from '../../utilities/tokenHandlers.js';

const { Dragger } = Upload;

export default function NewUpdate() {
  const { message } = App.useApp()
  const [selectedOp, setSelectedOp] = useState('');
  const [fileList, setFileList] = useState([]);
  const [filename, setFilename] = useState('');
  const [vins, setVins] = useState([]);
  const [region, setRegion] = useState(undefined);
  const [device, setDevice] = useState(undefined);
  const [inputs, setInputs] = useState({});
  const [checkingFeature, setCheckingFeature] = useState(false);
  const [emptyFeature, setEmptyFeature] = useState(false);
  const [featureValidateTriggered, setFeatureValidateTriggered] = useState(false);
  const [validFeature, setValidFeature] = useState(false);
  const [checkingPolicy, setCheckingPolicy] = useState(false);
  const [emptyPolicy, setEmptyPolicy] = useState(false);
  const [policyValidateTriggered, setPolicyValidateTriggered] = useState(false);
  const [validPolicy, setValidPolicy] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [ongoingOp, setOngoingOp] = useState(undefined);
  const [checkedOngoing, setCheckedOngoing] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    getLiveUpdate().then((data) => {
      if (data.length > 0){
        setOngoingOp(data[0]);
      }
      setCheckedOngoing(true);      
    }).catch((err) => {
      console.log(err);
    })
  }, [])

  const handleFileChange = async (info) => {
    setInputs({});
    setValidFeature(false);
    setFeatureValidateTriggered(false);
    setEmptyFeature(false);
    setValidPolicy(false);
    setPolicyValidateTriggered(false);
    setEmptyPolicy(false);
    if (!info.fileList.length) {
      message.info("Input file cleared.");
    }
    else {
      // setReadingFile(true);
      // message.info("Processing file contents.");
      const data = await info.file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const vins = Array.from(new Set(XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: ""
      }).map(row => row[0])));
      if (vins.length && vins[0].length > 7 && vins.length <= 25000) {
        message.loading('Validating VIN List...',2);
        getVINProfile(vins[0],(region||'na')).then(data => {
          if (data?.vin === vins[0]){
            setFileList([...info.fileList]);
            setVins([...vins]);
            setFilename(info.file.name);
          } 
          else {
            setFileList([]);
            setVins([]);
            setFilename('');
            message.error(
              <div>
                Your list of VINs seems to be invalid. <br />
                <ul style={{textAlign:'left', marginRight:'2rem'}}>
                  <li>Check selected region.</li>
                  <li>Check if the first column contains VINs.</li>
                  <li>Ensure the VINs are in the first sheet.</li>
                  <li><b>No headers</b></li>
                </ul>
              </div>,
              10
            );
          }
        }).catch(err => {
          console.log(err);
          setFileList([]);
          setVins([]);
          setFilename('');
          message.error("VIN list could not be validated.", 5);
        })
      }
      else if (vins.length > 25000) {
        setFileList([]);
        setVins([]);
        setFilename('');
        message.error(
          <div>
            Your file contains more than 25000 VINs. <br />
            Please post larger requests in <b>#data-campaigns</b> channel on Discord.
          </div>,
          10
        );
      }
      else {
        message.error('File seems to be invalid. Please check your file.')
      }
      // console.log(vins);
      // setReadingFile(false);
    }
  }

  const enableSubmit = () => {
    if (selectedOp && vins.length > 0 && filename && region && device) {
      switch (selectedOp) {
        case 'update-features':
          if (inputs['featureName'] && inputs['source'] && inputs['expiryDate'] && validFeature) return true;
          break;
        case 'delete-features':
          if (inputs['featureName'] && inputs['source'] && validFeature) return true;
          break;
        case 'schedule-policy':
          if (inputs['startupId'] && validPolicy) return true;
          break;
        default:
          return false;
      }
    }
    else return false;
  }

  const validateFeature = ({target: {value}}) => {
    if (value.length) {
      setFeatureValidateTriggered(true);
      setCheckingFeature(true);
      setEmptyFeature(false);
      getFeature(value).then(data => {
        setCheckingFeature(false);
        if (data.fname === value) {
          setValidFeature(true);
          message.success("Feature name verified.", 5);
        }
        else {
          setValidFeature(false);
          message.error("Invalid Feature name.", 5);
        }
      }).catch(err => {
        setCheckingFeature(false);
        setValidFeature(false);
        message.error("Feature name could not be verified. API call failed.", 5);
        console.log(err);
      });
    }
    else {
      setValidFeature(false);
      setEmptyFeature(true);
    }
  }

  const validatePolicy = ({target: {value}}) => {
    if (value.length) {
      setPolicyValidateTriggered(true);
      setCheckingPolicy(true);
      setEmptyPolicy(false);
      getPolicy(value).then(data => {
        setCheckingPolicy(false);
        if (data.id === Number(value)) {
          setValidPolicy(true);
          message.success("Policy ID verified.", 5);
        }
        else {
          setValidPolicy(false);
          message.error("Invalid Policy ID.", 5);
        }
      }).catch(err => {
        setCheckingPolicy(false);
        setValidPolicy(false);
        message.error("Policy ID could not be verified. API call failed.", 5);
        console.log(err);
      });
    }
    else {
      setValidPolicy(false);
      setEmptyPolicy(true);
    }
  }

  const handleSubmit = () => {
    setSubmitting(true);
    message.loading("Submitting request...",2);
    let timestampNow = String(new Date().valueOf());
    let payload = {
      operation: selectedOp,
      startTime: timestampNow,
      vins: vins,
      filename: filename,
      region: region,
      device: device,
      user: {
        username: getUserName(),
        email: getUserName()
      },
      ...inputs
    };
    startNewUpdate(payload).then(data => {
      if (data.status === 'started') {
        message.success("Operation started successfully. Redirecting...", 3);
        setTimeout(() => {
          navigate('/update-history');
        }, 500);
      }
      else {
        message.error("Error starting update on server side. Please get in touch with ops team or try after some time.")
      }
    }).catch(err => {
      setSubmitting(false);
      message.error("Request could not be submitted. API call failed.", 5);
      console.log(err);
    });
    // setTimeout(() => {
    //   message.success("Operation started successfully. Redirecting...", 3);
    //   setTimeout(() => {
    //     navigate('/update-history');
    //   }, 3000);
    // },2000)
  }

  return (
    <div id='form-container'>
    {!checkedOngoing && <Spin fullscreen />}
    {checkedOngoing && (
      ongoingOp ?
        <Result
          icon={<LoadingOutlined />}
          title={
            <div style={{color: '#777', marginTop:'10%', lineHeight:'1.75em'}}>
              There is another batch op currently in progress, <br/>
              please wait till it's finished to start a new update.
            </div>
          }
          extra={[
            <Button type='primary' size='large' onClick={() => navigate('/update-history')}>
              View Progress
            </Button>,
          ]}
        />
      :
        <>
          <div className="input-label">Operation Type</div>
          <Select
            options={OperationOptions}
            placeholder="Select Operation"
            disabled={submitting}
            onChange={(op) => {
              setSelectedOp(op);
              setFileList([]);
              setVins([]);
              setFilename('');
              setRegion(undefined);
              setDevice(undefined);
              setInputs({});
              setValidFeature(false);
              setFeatureValidateTriggered(false);
              setEmptyFeature(false);
              setValidPolicy(false);
              setPolicyValidateTriggered(false);
              setEmptyPolicy(false);
            }}
            style={{width:'100%'}}
          />
          {selectedOp &&
            <>
              <Divider style={DividerCSS} dashed />
              <div className="input-label">Region</div>
              <Radio.Group
                options={[
                  { label: <div className='radio-options'>NA</div>, value: 'na' },
                  { label: <div className='radio-options'>EU</div>, value: 'eu' },
                ]}
                disabled={submitting}
                value={region}
                onChange={(e) => setRegion(e.target.value)}
                optionType='button'
                buttonStyle='solid'
              /><br /><br />
              <div className="input-label">Device</div>
              <Radio.Group
                options={[
                  { label: <div className='radio-options'>Default</div>, value: 'default' },
                  { label: <div className='radio-options'>VP4R</div>, value: 'vp4r' },
                  { label: <div className='radio-options'>R1</div>, value: 'r1' },
                  { label: <div className='radio-options'>TBM</div>, value: 'tbm' }
                ]}
                disabled={submitting}
                value={device}
                onChange={(e) => setDevice(e.target.value)}
                optionType='button'
                buttonStyle='solid'
              />
              <br />
            </>
          }
          {region && device &&
            <>
              <Divider style={DividerCSS} dashed />
              <Dragger
                type='file'
                multiple={false}
                maxCount={1}
                disabled={submitting}
                customRequest={({ onSuccess }) => { onSuccess("ok"); }}
                onChange={handleFileChange}
                beforeUpload={() => false}
                onRemove={(file) => { setVins([]); setFileList([]); }}
                fileList={fileList}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ backgroundColor: '#efefef', borderWidth: '3px' }}
              >
                {(vins.length && fileList.length) ?
                  <>
                    <p className="ant-upload-drag-icon">
                      <CheckCircleFilled style={{ fontSize: '2.5em', color: 'limegreen' }} />
                    </p>
                    <p className="ant-upload-text">Unique VINs: <b>{vins.length}</b></p>
                    <p className="ant-upload-hint">
                      Click here to replace current file.
                    </p>
                  </>
                  :
                  <>
                    <p className="ant-upload-drag-icon">
                      <FileExcelOutlined style={{ fontSize: '2.5em' }} />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      <b>Max. 25000 VINs</b><br/>
                      Upload excel file containing only list of VINs with no headers.
                    </p>
                  </>
                }
              </Dragger>
            </>
          }
          {vins.length > 0 && filename &&
            <>
              <Divider style={DividerCSS} dashed />
              {(['update-features', 'delete-features']).includes(selectedOp) &&
                <>
                  <div className="input-label">Feature Name</div>
                  <Input
                    placeholder="Enter Feature Name"
                    value={inputs?.featureName || ''}
                    onChange={e => {
                      setInputs({ ...inputs, featureName: e.target.value });
                      setValidFeature(false);
                      setFeatureValidateTriggered(false);
                      e.target.value.length && setEmptyFeature(false);
                    }}
                    onBlur={validateFeature}
                    disabled={checkingFeature || submitting}
                    status={(inputs.featureName && featureValidateTriggered && !validFeature && !checkingFeature) ? 'error' : (emptyFeature? 'warning': '')}
                    suffix={checkingFeature ? 
                      <LoadingOutlined /> : 
                      (validFeature? 
                        <CheckOutlined style={{color: 'limegreen'}} /> : 
                        (featureValidateTriggered ?
                          (inputs.featureName ? <CloseOutlined style={{color: 'red'}} /> : <></> ) :
                          <></> 
                        )
                      )
                    }
                  />
                  <br /><br />
                  <div className="input-label">Source</div>
                  <Input
                    placeholder="Enter Source Name"
                    disabled={submitting}
                    value={inputs?.source || ''}
                    onChange={e => setInputs({ ...inputs, source: e.target.value })}
                  />
                  <br /><br />
                  {selectedOp === 'update-features' &&
                    <>
                      <div className="input-label">Feature Expiry Date</div>
                      <DatePicker
                        style={{ width: '100%' }}
                        disabled={submitting}
                        minDate={dayjs().add(1, 'day')}
                        onChange={(date, dateString) => setInputs({ ...inputs, expiryDate: (dateString + "T23:59:59.000Z") })}
                        placeholder="Select Feature Expiry Date"
                        inputReadOnly
                      />
                    </>
                  }
                </>
              }
              {selectedOp === 'schedule-policy' &&
                <>
                  <div className="input-label">Policy Name</div>
                  <Input
                    placeholder="Enter Policy ID"
                    value={inputs?.startupId || ''}
                    onChange={({ target: { value } }) => { 
                      if (!isNaN(Number(value))) setInputs({ ...inputs, startupId: value });
                      setValidPolicy(false);
                      setPolicyValidateTriggered(false);
                      value.length && setEmptyPolicy(false);
                    }}
                    onBlur={validatePolicy}
                    disabled={checkingPolicy || submitting}
                    status={(inputs.startupId && policyValidateTriggered && !validPolicy && !checkingPolicy) ? 'error' : (emptyPolicy ? 'warning': '')}
                    suffix={checkingPolicy ?
                      <LoadingOutlined /> :
                      (validPolicy ?
                        <CheckOutlined style={{color: 'limegreen'}} /> :
                        (policyValidateTriggered ?
                          (inputs.startupId ? <CloseOutlined style={{color: 'red'}} /> : <></> ) :
                          <></>
                        )
                      )
                    }
                  />
                </>
              }
            </>
          }
          {enableSubmit() &&
            <>
              <Divider style={DividerCSS} />
              <Popconfirm
                title="Start new update"
                description="Are you sure you want to submit this update request?"
                okText="Yes"
                cancelText="Cancel"
                onConfirm={handleSubmit}
              >
                <Button
                  type="primary"
                  loading={submitting}
                  size='large'
                >
                  SUBMIT
                </Button>
              </Popconfirm>
            </>
          }
        </>
    )}
    </div>
  )
}

const OperationTypes = {
  "update-features": "Add Feature",
  "delete-features": "Delete Feature",
  "schedule-policy": "Schedule Policy",
  "unschedule-policy": "Unschedule Policy",
  "prod-remove-features": "Return to New (RTN)"
};
const OperationOptions = Object.keys(OperationTypes).map((k) => ({ value: k, label: OperationTypes[k] }));
const DividerCSS = { margin: '18px 0 12px' }