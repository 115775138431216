import Cookies from 'js-cookie';
import { decodeToken } from 'react-jwt';

const myToken = process.env.REACT_APP_LOCAL_TOKEN;

export const env = process.env.REACT_APP_DEPLOY_ENV;
export const adaApiKey = process.env.REACT_APP_ADA_API_KEY;
export const batchOpsBaseURL = process.env.REACT_APP_OPS_API_GW_BASE_URL;
export const batchOpsBaseURLDev = process.env.REACT_APP_OPS_API_GW_BASE_URL_DEV;

export const cookieName = `ACCESS_TOKEN-${env}`;
export const token = env === 'local' ? myToken : Cookies.get(cookieName);
export const decodedToken = decodeToken(token);

export const adaWebURL = env === 'prod' ? 'https://alldataapp.com' : 'https://dev.alldataapp.com';
export const adaWebDomain = env === 'prod' ? '.alldataapp.com' : '.dev.alldataapp.com';
export const adaAPIsBaseUrlNA = env === 'prod' ? 'https://api.alldataapp.com' : 'https://api.dev.alldataapp.com';
export const adaAPIsBaseUrlEU = env === 'prod' ? 'https://emea.api.alldataapp.com' : 'https://emea.api.dev.alldataapp.com';

export const adaAPIEndpoints = {
  policyEndpoint: '/v1/policy',
  vinProfileEndpoint: '/v1/vin',
  vinProfileByDeviceEndpoint: '/v1/vin/device'
}
export const adaWebEndpoints = {
  showVinEndpoint: '/vins',
  showPolicyEndpoint: '/policies',
}
export const batchOpsAPIEndpoints = {
  newUpdateEndpoint: '/updates/new',
  liveStatusEndpoint: '/updates/livestatus',
  downloadEndpoint: '/updates/download',
  listUpdatesEndpoint: '/updates/list',
  getStaticGraphDataEndpoint: '/graph' 
}

console.log("Env:", env);
if (token) {
  const tokenData = decodeToken(token);
  if (tokenData)
    console.log("Username:", tokenData?.username);
  else
    console.log("Invalid token:", token);
}
// console.log(token)