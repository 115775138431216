import axios from "axios";
import { adaAPIsBaseUrlNA, batchOpsBaseURL, adaAPIEndpoints, batchOpsAPIEndpoints, token, adaApiKey, batchOpsBaseURLDev, adaAPIsBaseUrlEU } from "../config.js";

const adaApiNA = axios.create({
  baseURL: adaAPIsBaseUrlNA,
});
const adaApiEU = axios.create({
  baseURL: adaAPIsBaseUrlEU,
});
const batchOpsAPI = axios.create({
  baseURL: batchOpsBaseURL,
});
const batchOpsAPIDev = axios.create({
  baseURL: batchOpsBaseURLDev,
});

const adaAPIHeaders = {
  'Authorization': `Bearer ${token}`,
  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
  'X-Api-Key': adaApiKey
}

const batchOpsAPIHeaders = {
  'Authorization': `Bearer ${token}`
}

export function getFeature(fname) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await adaApiNA.get(adaAPIEndpoints.policyEndpoint, {params: {fname}, headers: adaAPIHeaders});
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

export function getPolicy(policyId) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await adaApiNA.get(`${adaAPIEndpoints.policyEndpoint}/${policyId}`, { headers: adaAPIHeaders });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

export function getVINProfile(vin, region) {
  return new Promise(async (resolve, reject) => {
    const adaAPI = region === 'na' ? adaApiNA : adaApiEU;
    try {
      const response = await adaAPI.get(`${adaAPIEndpoints.vinProfileEndpoint}/${vin}`, { headers: adaAPIHeaders });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

export function getVINProfileByDevice(vin,region) {
  return new Promise(async (resolve, reject) => {
    const adaAPI = region === 'na' ? adaApiNA : adaApiEU;
    try {
      const response = await adaAPI.get(`${adaAPIEndpoints.vinProfileByDeviceEndpoint}/${vin}`, { headers: adaAPIHeaders });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

export function startNewUpdate(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await batchOpsAPI.post(batchOpsAPIEndpoints.newUpdateEndpoint, payload, { headers: batchOpsAPIHeaders });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

export function getUpdatesHistory(cursor=null, page=0) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await batchOpsAPI.get(batchOpsAPIEndpoints.listUpdatesEndpoint, { headers: batchOpsAPIHeaders, params: { cursor:cursor, page:page } });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

export function getGraphData(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await batchOpsAPIDev.get(batchOpsAPIEndpoints.getStaticGraphDataEndpoint, { params: data, headers: batchOpsAPIHeaders });
      resolve(response.data);
    } catch (cause) {
      reject(cause);
    }
  });
}

export function getLiveUpdate() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await batchOpsAPI.get(batchOpsAPIEndpoints.liveStatusEndpoint, { headers: batchOpsAPIHeaders });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}

export function getDownloadFile(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await batchOpsAPI.post(batchOpsAPIEndpoints.downloadEndpoint, payload, { headers: batchOpsAPIHeaders });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
}